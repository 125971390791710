import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Drawer, SvgIcon, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import MenuCard from './MenuCard';
import { drawerWidth } from 'store/constant';
import { useNavigate } from 'react-router-dom';
import { Download } from '@mui/icons-material';

// ==============================|| SIDEBAR DRAWER ||============================== //
function CurvedArrowIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M3 15c0-.55.45-1 1-1h6c.55 0 1-.45 1-1V7.41l1.88 1.88a.996.996 0 101.41-1.41L10.71 4.29a1.003 1.003 0 00-1.42 0L6.71 6.88a.996.996 0 101.41 1.41L9 7.41V13H4c-.55 0-1 .45-1 1s.45 1 1 1h8c.55 0 1-.45 1-1V5.41l2.29 2.29a.996.996 0 101.41-1.41L12.71 2.29a.996.996 0 00-1.42 0L6.71 6.88a.996.996 0 101.41 1.41L9 7.41V13c0 .55-.45 1-1 1H4c-.55 0-1 .45-1 1z"
      />
    </SvgIcon>
  );
}
const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const navigate = useNavigate(); // 获取导航函数
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const drawer = (
    <>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
          <LogoSection />
        </Box>
      </Box>
      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
            paddingLeft: '16px',
            paddingRight: '16px'
          }}
        >
          <MenuList />
          <MenuCard />
          <Box sx={{ flexGrow: 1, marginBottom: '20px'  }} >
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/')}
            sx={{ marginRight: '8px' }}
          >
            <Download sx={{ marginRight: '4px'}} /> {/* 左侧箭头图标 */}
            抱脸虫客户端(方案1)
          </Button>
          </Box>
          <Box sx={{ flexGrow: 1, marginBottom: '20px'  }} >
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/boost')}
            sx={{ marginRight: '8px' }}
          >
            <Download sx={{ marginRight: '4px'}} /> {/* 左侧箭头图标 */}
            加速猫客户端(方案2)
          </Button>
          </Box>

        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList />
          <MenuCard />
        </Box>
      </MobileView>
    </>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            [theme.breakpoints.up('md')]: {
              top: '88px'
            }
          }
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default Sidebar;
